.App {
  max-width: 600px;
  margin: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h4 {
  margin-bottom: 0px;
}

img {
  display: block;
}

.qr-box {
  width: 15rem;
  height: 15rem;
  position:relative;
}

.qr {
  width: 100%;
  height: 100%;
}

.qr-overlay{
  position:absolute;
  top:calc(6.5rem - .05rem);
  left:calc(25% - .05rem);
  width: 50%;
  height: 2rem;
  background-color: white;
  padding: .1rem;

}

h4 button {
  margin-left: .5rem;
}

li button {
  margin-right: .5rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
